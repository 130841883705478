<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 24"
  >
    <path
      fill="currentColor"
      d="M1 24a1 1 0 0 1-1-1V1A1 1 0 0 1 1.5.1l20 11a1 1 0 0 1 0 1.8l-20 11a1 1 0 0 1-.5.1Z"
    />
  </svg>
</template>
